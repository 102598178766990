var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"ma-0 pa-0 justify-center user_reminder"},[_c('h3',{staticClass:"mb-3"},[_c('v-icon',{staticClass:"title_icon",attrs:{"color":"black"}},[_vm._v(" mdi-calendar-check ")]),_vm._v(" "+_vm._s(_vm.$t("common.reminder"))+": "),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"fab":"","x-small":"","elevation":"0","icon":""},on:{"click":_vm.render}},[_c('v-icon',{staticStyle:{"font-size":"1.6rem"},attrs:{"color":"#212121"}},[_vm._v("mdi-refresh")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mb-5",staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[(_vm.showCards)?_c('div',{staticStyle:{"width":"360px","margin":"0 auto 0 auto"}},[_c('vue-card-stack',{staticStyle:{"margin-top":"-15px"},attrs:{"cards":_vm.cards,"scaleMultiplier":0.2,"stack-width":360,"card-width":320},scopedSlots:_vm._u([{key:"card",fn:function({ card }){return [_c('div',{staticClass:"pa-2 memorial_card",staticStyle:{"width":"100%","height":"100%","border-radius":"12px"},style:({ background: card.background })},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-avatar',{attrs:{"size":"64px","tile":""}},[_c('img',{attrs:{"alt":"Avatar","src":(card.medicine && card.medicine.avatar) ||
                    '/icons/bell.png'},on:{"click":function($event){return _vm.$router.push(`/medicine/${card.medicineId}`)}}})])],1),_c('h3',{staticClass:"text-center mb-1 mt-3 w-100",staticStyle:{"font-size":"1.3rem"},on:{"click":function($event){return _vm.$router.push(`/medicine/${card.medicineId}`)}}},[_vm._v(" "+_vm._s((card.notification && card.notification.title) || _vm.$t("medicine.name"))+" ")]),_c('p',[_vm._v(" "+_vm._s(card.notification && card.notification.body)+" ")]),_c('count-down',{attrs:{"auto-start":true,"auto-seconds":card.seconds}}),_c('div',{staticClass:"d-flex justify-space-around mt-1"},[_c('v-btn',{staticClass:"red-text",staticStyle:{"width":"45%"},attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.$refs.PopupConfirmAction.open(
                    card.actionId,
                    'cancel',
                    card.medicine.medicineName
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")]),(card.seconds > -1800)?_c('v-btn',{staticStyle:{"width":"50%"},attrs:{"dark":"","color":"dark","rounded":"","elevation":"0"},on:{"click":function($event){return _vm.$refs.PopupConfirmAction.open(
                    card.actionId,
                    'done',
                    card.medicine.medicineName
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("common.confirm"))+" ")]):_vm._e()],1),_c('p',{staticClass:"mt-2 pa-0",staticStyle:{"font-size":"0.8rem"}},[_vm._v(" "+_vm._s(card.dateCreatedString)+" ")])],1)]}}],null,false,3141471928)})],1):_c('div',{staticClass:"mb-3"},[_c('lottie-loader',{attrs:{"url":'/lottie/no-data.json',"width":'270px'}}),_c('h5',{staticClass:"no_data mt-3",domProps:{"innerHTML":_vm._s(_vm.$t('common.reminder_nodata'))}})],1)]),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"pa-5",attrs:{"dark":"","color":"dark","rounded":"","small":""},on:{"click":function($event){return _vm.$router.push('/medicine')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("common.add"))+" "+_vm._s(_vm.$t("common.reminder"))+" ")],1)],1),_c('popup-confirm-action',{ref:"PopupConfirmAction"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }